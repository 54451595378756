import PropTypes from "prop-types";
import { useContext, useState } from "react";
import "../../../entries/tinymce";
import { Editor } from "@tinymce/tinymce-react";
import { uctrans } from "@/lib/Translator";
import Dialog from "../../../../../../js/react/components/general/Dialog";
import { CmsTypesContext } from "../../cms/cms-types-context";
import MediaLibrary from "../../media/library/MediaLibrary";
import "tinymce/plugins/advlist";
import tinymceWebsiteCssUrl from "@crmstyle/tinymce_website_content.scss?url";
import tinymceTextCssUrl from "@crmstyle/tinymce_text_content.scss?url";
import tinymceMailCssUrl from "@crmstyle/tinymce_mail_content.scss?url";

export default function TextEditorComponent(props) {
	let { height } = props;
	const { disabled } = props;

	const [currentDialogApi, setCurrentDialogApi] = useState(null);
	const context = useContext(CmsTypesContext);
	const {
		tagTypes,
		pageOptions,
		documentOptions,
		newsitemOptions,
		definitionOptions,
		CTLandingOptions,
		questionnaireOptions,
	} = context || {};

	const contentStyle = () => {
		if (disabled) {
			return "body { background-color: hsl(0, 0%, 95%); color: hsl(0, 0%, 60%); }";
		}
		return "";
	};

	const config = {
		content_style: contentStyle(),
		language: "nl",
		branding: false,
		menubar: false,
		statusbar: false,
		skin: false,
		relative_urls: false,
		browser_spellcheck: true,
		contextmenu: false,
		tags: tagTypes,
		definitions: definitionOptions,
		internal: pageOptions,
		document: documentOptions,
		news: newsitemOptions,
		ctLandingOptions: CTLandingOptions,
		questionnaireOptions,
		valid_elements:
			"strong/b,i/em,u,a[href|target|rel],p,br,ol,ul,li,ins,h1,h2,h3,h4,h5,h6,table[align|class],thead,tbody,tr,th[align],td[align],span[style]",
		document_picker: dialogApi => {
			setCurrentDialogApi(dialogApi);
		},
	};

	const onEditorChange = content => {
		props.onChange(content);
	};

	const onDocumentSelected = selectedDocument => {
		if (selectedDocument) {
			const data = currentDialogApi.getData(); // current form data;
			data.url = selectedDocument.name;
			data.name = !data.customName ? selectedDocument.name : data.name;
			// new document
			if (!documentOptions.some(e => e.id === selectedDocument.id)) {
				config.document.push(selectedDocument);
			}
			currentDialogApi.setData(data);
			setCurrentDialogApi(null);
		}
	};

	const tagPlugin = tagTypes && Object.keys(tagTypes).length ? "tags" : "";

	switch (props.type) {
		case "text":
			config.content_css = tinymceTextCssUrl;
			config.plugins = `custom_links link code ${tagPlugin}`;
			config.contextmenu = "";
			config.toolbar = props.hideToolbar ? false : `undo redo | custom_links unlink | ${tagPlugin} | code`;
			config.forced_root_block = false;
			config.selector = "textarea";
			config.valid_elements = "br";
			break;
		case "basic":
			config.content_css = tinymceTextCssUrl;
			config.plugins = `lists`;
			config.toolbar = `undo redo | bold italic underline | bullist numlist |`;
			config.forced_root_block = false;
			config.selector = "textarea";
			break;
		case "website_listitem":
			config.content_css = tinymceWebsiteCssUrl;
			config.plugins = `custom_links link ${tagPlugin}`;
			config.toolbar = props.hideToolbar
				? false
				: `undo redo | bold italic underline strikethrough | custom_links unlink | ${tagPlugin}`;
			break;
		case "mail":
			config.content_css = tinymceMailCssUrl;
			config.plugins = `custom_links link code lists table ${tagPlugin}`;
			config.contextmenu = "";
			config.toolbar = props.hideToolbar
				? false
				: `undo redo | bold italic underline | forecolor backcolor | formatselect | bullist numlist | table | custom_links unlink | ${tagPlugin} | code`;
			height = props.height || 450;
			break;
		case "document":
			config.content_css = tinymceWebsiteCssUrl;
			config.plugins = `custom_links link code hr lists advlist ${tagPlugin} table`;
			config.valid_elements =
				"strong/b,i/em,u,a[href|target|rel],p,br,ol[style],ul[style],li,ins,h1,h2,h3,h4,h5,h6,table[align|class],thead,tbody,tr,th[align],td[align],span[style]";
			config.forced_root_block = !props.hideToolbar;
			config.indent_use_margin = true;
			config.toolbar = props.hideToolbar
				? false
				: `undo redo | bold italic underline strikethrough | table | indent outdent | bullist numlist | custom_links unlink | ${tagPlugin} | code`;
			break;
		case "website":
		default:
			config.content_css = tinymceWebsiteCssUrl;
			config.plugins = `custom_links link code hr lists ${tagPlugin}`;
			config.forced_root_block = !props.hideToolbar;
			config.toolbar = props.hideToolbar
				? false
				: `undo redo | bold italic underline strikethrough | bullist numlist | custom_links unlink | ${tagPlugin} | code`;
			break;
	}

	if (height) {
		config.height = height;
	}

	if (props.autoResize) {
		config.plugins += " autoresize";
	}

	return (
		<>
			<Dialog
				className="TinyMCE_Modal_dialog"
				isOpen={currentDialogApi !== null}
				onClose={() => setCurrentDialogApi(null)}
				title={uctrans("media.select_:media", {}, { media: `media.document.singular` })}
				width={1000}>
				<MediaLibrary
					mode={MediaLibrary.MODE_SELECTOR}
					type={MediaLibrary.TYPE_DOCUMENT}
					onSelect={onDocumentSelected}
				/>
			</Dialog>
			<Editor
				value={props.value}
				init={{ ...config, ...props.overrideConfig }}
				onEditorChange={onEditorChange}
				disabled={props.disabled}
			/>
		</>
	);
}
TextEditorComponent.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	type: PropTypes.string,
	disabled: PropTypes.bool,
	height: PropTypes.number,
	overrideConfig: PropTypes.object,
	hideToolbar: PropTypes.bool,
	autoResize: PropTypes.bool,
};

TextEditorComponent.defaultProps = {
	type: "website",
	disabled: false,
	autoResize: false,
	overrideConfig: {},
};
