import axios from "axios";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { forceCheck } from "react-lazyload";
import { route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import Translate from "../../../../../../js/react/components/general/Translate";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import { Tab } from "../../general/tabs/Tab";
import { Tabs } from "../../general/tabs/Tabs";
import MediaLibraryContext from "./MediaLibraryContext";
import MediaLibraryDeleteButton from "./MediaLibraryDeleteButton";
import MediaLibraryDocuments from "./MediaLibraryDocuments";
import MediaLibraryFilters from "./MediaLibraryFilters";
import MediaLibraryFolders from "./MediaLibraryFolders";
import MediaLibraryMoveButton from "./MediaLibraryMoveButton";
import MediaLibraryPictures from "./MediaLibraryPictures";
import MediaLibrarySyncButton from "./MediaLibrarySyncButton";
import MediaLibraryUploadButton from "./MediaLibraryUploadButton";
import MediaLibraryVideos from "./MediaLibraryVideos";
import MediaLibraryAnimations from "./MediaLibraryAnimations";

const MediaLibrary = function (props) {
	const [loading, setLoading] = useState(true);
	const [type, setType] = useState(props.type);
	const [filters, setFilters] = useState({ query: "", sort: "updated_at" });

	const [marketingThemes, setMarketingThemes] = useState([]);
	const [folders, setFolders] = useState([]);
	const [folderCounts, setFolderCounts] = useState([]);
	const [currentFolder, setCurrentFolder] = useState(null);

	const [media, setMedia] = useState([]);
	const [filteredMedia, setFilteredMedia] = useState([]);
	const [selected, setSelected] = useState([]);
	const abortFolders = useRef(null);
	const abortMedia = useRef(null);

	const refreshFolders = useCallback(async () => {
		if (!type) {
			return;
		}
		try {
			if (abortFolders.current) {
				abortFolders.current.abort();
			}
			abortFolders.current = new AbortController();
			const response = await axios.get(route(`crm.media_folder.index`), {
				params: { type },
				signal: abortFolders.current.signal,
			});
			setFolders(response.data.folders);
			setFolderCounts(response.data.folder_counts);
		} catch (error) {
			console.error(error);
			setFolders([]);
		}
	}, [type]);

	useEffect(() => {
		let filteredMedia = [...media];

		if (filters.query.length) {
			filteredMedia = filteredMedia.filter(
				media => media.name.toLowerCase().indexOf(filters.query.toLowerCase()) !== -1,
			);
		}

		if (currentFolder) {
			filteredMedia = filteredMedia.filter(
				media => media.media_folder_id === (currentFolder === -1 ? null : currentFolder),
			);
		}

		if (filters.sort) {
			const desc = filters.sort === "created_at" || filters.sort === "updated_at";
			filteredMedia = filteredMedia.sort((a, b) => {
				const valA = a[filters.sort].toLowerCase();
				const valB = b[filters.sort].toLowerCase();
				if (valA < valB) {
					return desc ? 1 : -1;
				}
				if (valA > valB) {
					return desc ? -1 : 1;
				}
				return 0;
			});
		}

		setFilteredMedia(filteredMedia);
	}, [filters.query, filters.sort, currentFolder, media]);

	useEffect(() => {
		if (type === MediaLibrary.TYPE_IMAGE) {
			// This forces react-lazyload to check if media is in viewport without a scroll event
			forceCheck();
		}
	}, [filteredMedia, type]);

	const lastDebounceCall = useRef(0);
	const debounce = callback => {
		const timeBefore = new Date().getTime();
		lastDebounceCall.current = timeBefore;

		window.setTimeout(() => {
			if (timeBefore === lastDebounceCall.current) {
				callback();
			}
		}, 400);
	};

	const refreshMedia = useCallback(async () => {
		if (!type) {
			return;
		}
		try {
			if (abortMedia.current) {
				abortMedia.current.abort();
			}
			abortMedia.current = new AbortController();

			const params = {};
			if (currentFolder) {
				params.folder = currentFolder;
			}
			refreshFolders();
			let { data } = await axios.get(route(`crm.${type}.index`), { params, signal: abortMedia.current.signal });
			if (!Array.isArray(data)) {
				data = [];
			}
			setSelected([]);
			setMedia(data.filter(mediaItem => type === MediaLibrary.TYPE_VIDEO || mediaItem.file !== null));
			setLoading(false);
		} catch (error) {
			console.error(error);
			setMedia([]);
		}
	}, [currentFolder, refreshFolders, type]);

	useEffect(() => {
		setCurrentFolder(null);
		setSelected([]);
		debounce(refreshMedia);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const onTabChange = useCallback(index => {
		setLoading(true);
		setFilteredMedia([]);

		switch (index) {
			case 0:
				setType(MediaLibrary.TYPE_IMAGE);
				break;
			case 1:
				setType(MediaLibrary.TYPE_VIDEO);
				break;
			case 2:
				setType(MediaLibrary.TYPE_DOCUMENT);
				break;
			case 3:
				setType(MediaLibrary.TYPE_ANIMATION);
				break;
		}
	}, []);

	const toggleSelect = useCallback(
		id => {
			const selectedCopy = [...selected];

			if (selectedCopy.indexOf(id) === -1) {
				selectedCopy.push(id);
			} else {
				selectedCopy.splice(selectedCopy.indexOf(id), 1);
			}
			setSelected(selectedCopy);
		},
		[selected],
	);

	const onMediaClick = useCallback(
		media => {
			if (props.mode === MediaLibrary.MODE_LIBRARY) {
				toggleSelect(media.id);
			} else if (props.mode === MediaLibrary.MODE_SELECTOR && props.onSelect) {
				props.onSelect(media);
			}
		},
		[props, toggleSelect],
	);

	const setFilter = useCallback(
		(name, value) => {
			setFilters({ ...filters, [name]: value });
		},
		[filters],
	);

	const getMediaRenderer = () => {
		switch (type) {
			case MediaLibrary.TYPE_IMAGE:
				return <MediaLibraryPictures />;
			case MediaLibrary.TYPE_DOCUMENT:
				return <MediaLibraryDocuments />;
			case MediaLibrary.TYPE_VIDEO:
				return <MediaLibraryVideos />;
			case MediaLibrary.TYPE_ANIMATION:
				return <MediaLibraryAnimations />;
		}
		return null;
	};

	const getMarketingThemes = async () => {
		try {
			const { data } = await axios.get(route(`crm.marketing.themes.active`), {});
			const marketingData = data.map(theme => ({
				value: theme.id,
				label: theme.name,
			}));
			setMarketingThemes(marketingData);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<MediaLibraryContext.Provider
			value={{
				mode: props.mode,
				type,
				filters,
				setFilter,
				folders,
				folderCounts,
				currentFolder,
				setCurrentFolder,
				refreshFolders,
				media: filteredMedia,
				refreshMedia,
				selected,
				onMediaClick,
				marketingThemes,
				getMarketingThemes,
			}}>
			{props.mode === MediaLibrary.MODE_LIBRARY && (
				<>
					<BreadCrumbs breadCrumbs={[{ label: uctrans("general.cms") }, { label: uctrans("media.plural") }]} />
					<Tabs onActiveTabChange={onTabChange}>
						<Tab title={uctrans("media.image.plural")} />
						<Tab title={uctrans("media.video.plural")} />
						<Tab title={uctrans("media.document.plural")} />
						<Tab title={uctrans("media.animation.plural")} />
					</Tabs>

					<div className="page-title-container">
						<h1 className="page-title">
							<Translate content={`media.${type}.plural`} />
						</h1>
					</div>
				</>
			)}

			{type !== "video" ? <MediaLibraryUploadButton /> : <MediaLibrarySyncButton />}

			{props.mode === MediaLibrary.MODE_LIBRARY && selected.length > 0 && (
				<>
					{type !== "video" && <MediaLibraryDeleteButton />}
					{folders.length > 0 && <MediaLibraryMoveButton />}
				</>
			)}

			<div className="flex">
				<div className="flex-1 mr-6 lg:mr-12">
					<MediaLibraryFilters />

					{loading ? (
						<div className="text-center flex-grow">
							<Spinner width={25} />
							<div>
								<Translate content="media.retrieving_:media" transReplaces={{ media: `media.${type}.plural` }} />
							</div>
						</div>
					) : media.length > 0 ? (
						getMediaRenderer()
					) : (
						<div className="text-center flex-grow">
							<Translate content="general.no_:items_found" transReplaces={{ items: `media.${type}.plural` }} />
						</div>
					)}
				</div>
				<div className="w-1/4">
					<MediaLibraryFolders />
				</div>
			</div>
		</MediaLibraryContext.Provider>
	);
};

MediaLibrary.TYPE_IMAGE = "image";
MediaLibrary.TYPE_VIDEO = "video";
MediaLibrary.TYPE_DOCUMENT = "document";
MediaLibrary.TYPE_ANIMATION = "animation";

MediaLibrary.MODE_LIBRARY = 1;
MediaLibrary.MODE_SELECTOR = 2;

MediaLibrary.propTypes = {
	type: PropTypes.oneOf([
		MediaLibrary.TYPE_IMAGE,
		MediaLibrary.TYPE_VIDEO,
		MediaLibrary.TYPE_DOCUMENT,
		MediaLibrary.TYPE_ANIMATION,
	]),
	mode: PropTypes.oneOf([MediaLibrary.MODE_LIBRARY, MediaLibrary.MODE_SELECTOR]),
	onSelect: PropTypes.func,
};

MediaLibrary.defaultProps = {
	type: MediaLibrary.TYPE_IMAGE,
	mode: MediaLibrary.MODE_LIBRARY,
};

MediaLibrary.allowedExtensions = {
	[MediaLibrary.TYPE_IMAGE]: ["jpg", "jpeg", "png", "gif", "bmp", "svg"],
	[MediaLibrary.TYPE_ANIMATION]: ["webm", "mp4"],
	[MediaLibrary.TYPE_DOCUMENT]: [
		"psd",
		"pdf",
		"doc",
		"docx",
		"txt",
		"rtf",
		"odt",
		"xml",
		"xls",
		"xlsx",
		"ppt",
		"pptx",
		"csv",
		"zip",
		"rar",
		"7z",
		"xlsm",
	],
};

export default MediaLibrary;
