import { useContext } from "react";
import MediaLibraryContext from "./MediaLibraryContext";
import MediaLibraryDocument from "./MediaLibraryDocument";

export default function MediaLibraryDocuments() {
	const { media } = useContext(MediaLibraryContext);

	return (
		<div className="mt-4">
			<div className="p-2 border-b flex font-bold">
				<span className="flex-grow cursor-pointer">Document</span>
				<span className="flex mr-4">Aangemaakt</span>
				<span className="flex mr-14">Gewijzigd</span>
				<span className="flex mr-9"></span>
			</div>
			{media.map(document => (
				<MediaLibraryDocument key={document.id} document={document} />
			))}
		</div>
	);
}
